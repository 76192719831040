<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        รหัสสายวิ่ง : {{ routeData.name }}
      </h3>
      <!-- <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >เพิ่มข้อมูล
        </button>
      </div> -->
    </div>

    <!-- <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหา"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div> -->

    <!-- <div class="card-body pt-3">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200"
            >
              <th>#</th>
              <th>รหัสลูกค้า</th>
              <th>ชื่อลูกค้า</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
              <td>
                {{ item.code ? item.code : "-" }}
              </td>
              <td>
                {{ item.name ? item.name : "-" }}
              </td>

              <td>
                <div class="row"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
    <!-- คั่น -->
    <div class="card-body">
      <div class="card-toolbar row justify-content-center">
        <div
          class="col-sm-5"
          style="
            border: solid lightgray 0.5px;
            padding: 20px;
            border-radius: 10px;
          "
        >
          <b><u>ลูกค้าทั้งหมด</u></b>
          <div>
            <el-checkbox
              type="checkbox"
              v-model="allLeftCheckbox"
              @change="checkAllitemsleftArray()"
            />
            <span class="form-check-label"> เลือกทั้งหมด </span>
          </div>
          <div
            v-for="customer in itemsleftArray"
            :key="customer"
            class="col-sm-12 me-1"
          >
            <label
              class="
                form-check form-check-sm form-check-custom form-check-solid
                me-5
                mb-2
                mt-2
              "
            >
              <el-checkbox type="checkbox" v-model="customer.isSelected" />
              <span class="form-check-label">
                {{ customer.name }}
              </span>
            </label>
          </div>
        </div>
        <div class="col-sm-1" style="align-self: center; text-align: center">
          <button
            type="button"
            class="btn btn-light btn-primary"
            style="font-size: 14px; font-weight: bold"
            @click="appendRightArray()"
          >
            <i class="fas fa-arrow-right"></i>
          </button>
          <button
            type="button"
            class="btn btn-light btn-danger"
            style="font-size: 14px; font-weight: bold; margin-top: 10px"
            @click="appendLeftArray()"
          >
            <i class="fas fa-arrow-left"></i>
          </button>
        </div>
        <div
          class="col-sm-5 ms-1"
          style="
            border: solid lightgray 0.5px;
            padding: 20px;
            border-radius: 10px;
          "
        >
          <b><u>Route ปัจจุบัน</u></b>
          <div>
            <el-checkbox
              type="checkbox"
              v-model="allRightCheckbox"
              @change="checkAllitemsrightArray()"
            />
            <span class="form-check-label"> เลือกทั้งหมด </span>
          </div>
          <div
            v-for="item in itemsrightArray"
            :key="item"
            class="col-sm-12 me-1"
          >
            <label
              class="
                form-check form-check-sm form-check-custom form-check-solid
                me-5
                mb-2
                mt-2
              "
            >
              <el-checkbox type="checkbox" v-model="item.isSelected" />
              <span class="form-check-label">
                {{ item.code }} {{ item.name }}
              </span>
            </label>
          </div>
        </div>
        <div></div>
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import customerAPI from "@/api/customer/";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";

export default {
  setup() {
    document.title = "bem-document-demo เพิ่มข้อมูลลูกค้า";
  },
  components: {
    DialogConfirmByPass,
    Pagination,
    Search,
    Loader,
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    checkAll: false,
    dialogConfirmByPass: false,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],
    routeData: [],
    itemsleftArray: [],
    itemsrightArray: [],
  }),

  async created() {
    await this.getRoute();
    await this.getAllCustomer();
    this.getCustomerByRouteId();
  },

  methods: {
    checkAllitemsleftArray() {
      if (this.allLeftCheckbox) {
        this.itemsleftArray.forEach((element) => {
          element.isSelected = true;
        });
      } else {
        this.itemsleftArray.forEach((element) => {
          element.isSelected = false;
        });
      }
    },
    checkAllitemsrightArray() {
      if (this.allRightCheckbox) {
        this.itemsrightArray.forEach((element) => {
          element.isSelected = true;
        });
      } else {
        this.itemsrightArray.forEach((element) => {
          element.isSelected = false;
        });
      }
    },
    appendRightArray() {
      this.itemsleftArray.forEach((element) => {
        if (element.isSelected == true) {
          let a = {
            ...element,
          };
          a.isSelected = false;
          console.log("aaaaaaaaaa", a);
          this.itemsrightArray.push(a);
        }
      });
      this.itemsleftArray = this.itemsleftArray.filter(
        (item) => item.isSelected !== true
      );
      this.allLeftCheckbox = false;
    },
    appendLeftArray() {
      this.itemsrightArray.forEach((element) => {
        if (element.isSelected == true) {
          let a = {
            ...element,
          };
          a.isSelected = false;
          this.itemsleftArray.push(a);
        }
        this.itemsrightArray = this.itemsrightArray.filter(
          (item) => item.isSelected !== true
        );
      });
      this.allRightCheckbox = false;
    },
    //////////////////////////////
    async getRoute() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await customerAPI.route.getOne(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.routeData = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllCustomer() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await customerAPI.customer.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        // let getVan = responseData.data;
        this.itemsleftArray = responseData.data;

        // console.log("getVan left", getVan);
        // console.log("itemsrightArray left", this.itemsrightArray);
        // this.itemsrightArray.forEach((element) => {
        //   this.itemsleftArray = getVan.filter((val) => val.id !== element.id);
        // });

        console.log("this.itemsleftArray", this.itemsleftArray);
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },

    async getCustomerByRouteId(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await customerAPI.customer.getCustomerByRouteId(
          this.$route.query.id
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.itemsrightArray = responseData.data;
        this.loading = false;
        // if (method === 1) {
        //   await this.$router.push({
        //     query: {
        //       page: this.$route.query.page,
        //       get: 1,
        //     },
        //   });
        // }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    goToNew() {
      this.$router.push({
        path: "/CUSTOMER/new",
        query: {},
      });
    },
    goToEdit(id) {
      this.$router.push({
        name: "CUSTOMER-edit",
        query: {
          id: id,
        },
      });
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `ต้องการลบ ${item.name} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await customerAPI.customer.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    async statusApprove(isApprove) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
      };
      updateResponse = await customerAPI.customer.update(id, this.itemApprove);
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          Swal.fire({
            icon: "success",
            title: `อนุมัติสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    textSearch(val) {
      console.log({ val });
    },

    async search() {
      this.loading = true;

      const responseSearch = await customerAPI.customer.search({
        search: this.searchInput,
      });

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
